
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import {
  clientConceptProductIndex,
  conceptProductIndex,
  ProductIndexItem,
} from "@/modules/product/api/conceptProductIndex";
import { conceptProductDestroy } from "@/modules/product/api/conceptProductDestroy";
import { bulkSend } from "@/modules/product/api/bulkSend";
import eventBus from "@/application/eventBus";
import ImportDialog from "@/modules/product/components/ImportDialog.vue";
import { exportproducts } from "@/modules/product/api/exportImport";
import { sendBlobToBrowserDownload } from "@/application/util/downloadFile";
import i18n from "@/plugins/i18n";
import dayjs from "@/plugins/dayjs";
import ProductFilter from "@/modules/product/components/ProductFilter.vue";
import BulkDeleteButton from "@/modules/crudTable/components/BulkDeleteButton.vue";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<ProductIndexItem> & { clientId: number; id: number }[];
  isImportDialogOpen: boolean;
  filterComponent: VueConstructor;
  defaultFilters: Record<string, unknown>;
}

export default Vue.extend({
  name: "productTable",
  components: {
    BulkDeleteButton,
    ImportDialog,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "articleNumber", typeParameters: { tooltipProperty: "errors" } },
      { value: "name", typeParameters: { tooltipProperty: "errors" } },
      {
        value: "createdAt",
        type: "date",
        typeParameters: { dateType: "DD MMM YYYY HH:mm" },
      },
      {
        value: "originalSource",
        typeParameters: { tooltipProperty: "errors" },
      },
      {
        value: "errors",
        type: "boolean",
        typeParameters: {
          trueText: i18n.t("salesOrder.hasNoErrors"),
          falseText: i18n.t("salesOrder.hasErrors"),
          revertValue: true,
        },
      },
    ],
    selected: [],
    isImportDialogOpen: false,
    filterComponent: ProductFilter,
    defaultFilters: {},
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
  },
  watch: {
    client: {
      handler() {
        this.refreshTable();
      },
      deep: true,
    },
  },
  created() {
    if (!this.isClient) {
      this.headers.unshift({ value: "clientName" });
    }
    this.setDefaultFilters();
  },
  methods: {
    setDefaultFilters() {
      if (this.isClient) return;
      this.defaultFilters = {
        dateFrom: undefined,
        dateTo: undefined,
      };
    },
    selectItems(ids: number[]): void {
      if (!this.client?.id) throw "Selecting id's when clientId is not set"; //@TODO for now this function is only used when client is set

      this.selected = ids.map((id) => ({ id, clientId: this.client?.id }));
    },
    productIndex(data: PaginatedRequest) {
      if (this.client) {
        return clientConceptProductIndex(data, this.client.id);
      }
      return conceptProductIndex(data);
    },
    async productDestroy(item: ProductIndexItem) {
      await conceptProductDestroy({
        clientId: item.clientId,
        productId: item.id,
      });
      this.selected = this.selected.filter(
        (selected) => selected.id !== item.id
      );
    },
    handleEdit(item: ProductIndexItem) {
      this.$router.push({
        name: "product.edit",
        params: {
          productId: item.id.toString(),
          clientId: item.clientId.toString(),
        },
      });
    },
    handleBulkEdit() {
      const productIds: string[] = this.selected.map((item) =>
        item.id.toString()
      );
      const clientIds: string[] = this.selected.map((item) =>
        item.clientId.toString()
      );

      this.$router.push({
        name: "product.edit",
        params: {
          productId: productIds[0].toString(),
          clientId: clientIds[0].toString(),
        },
        query: { productIds, clientIds },
      });
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
    async handleBulkSend() {
      try {
        if (!this.client?.id) throw "Selecting id's when clientId is not set"; //@TODO for now this function is only used when client is set
        const response = await bulkSend({
          clientId: this.client?.id,
          ids: this.selected.map((item) => item.id),
        });
        const { succeeded, failed } = response.data.data;
        this.alertSucceededAndFailedSendings(succeeded.length, failed.length);
        this.deselectItems(succeeded);
        this.refreshTable();
      } catch (e) {
        eventBus.$emit("snackbar", {
          text: this.$t("global.error"),
          color: "error",
        });
        throw e;
      }
    },
    alertSucceededAndFailedSendings(succeeded: number, failed: number) {
      eventBus.$emit("snackbar", {
        text: this.$t("product.messages.bulkSend", {
          succeeded: succeeded,
          failed: failed,
        }),
        color: succeeded > 0 ? "success" : "error",
      });
    },
    deselectItems(ids: number[]) {
      this.selected = this.selected.filter((item) => !ids.includes(item.id));
    },
    async downloadExport() {
      const response = await exportproducts(this.client.id);
      sendBlobToBrowserDownload("products", "xlsx", response.data);
    },
  },
});
